import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useState } from 'react'
// Components
import Hero from '@/components/home/Hero'
import Header from '@/components/newHeader/Header'
import PicCardMix from '@/components/picCardMix/picCardMix'
import CustomerReview from '@/components/search/CustomerReview'
import Footer from '@/components/newFooter'
import BasicHeadSeo from '@/components/basicHeadSeo'
import OurPartners from '@/components/ourPartners'
// utils
import useMediaQuery from '@/utils/publicApi'
import categoryPairs from '@/components/landings/util/arraySplit'
import useOnScreen from '@/hooks/useOnscreen.hook'
import { useStoreClickIds } from '@/hooks/useStoreClickIds'
// GraphQL
import {
  GET_ALL_LOCATIONS,
  GET_CATEGORIES,
  BREED_LIST_EXPLORER,
  PUPPY_SEARCH,
  GET_BREEDS_NAME_LIST,
} from '@/graphql/index'

import styles from './index.module.scss'

const How = dynamic(() => import('@/components/home/How'), { ssr: false })
const Why = dynamic(() => import('@/components/home/Why'), { ssr: false })
const Categories = dynamic(() => import('@/components/home/Categories'), {
  ssr: false,
})
const PuppyQuizBanner = dynamic(
  () => import('@/components/search/PuppyQuizBanner'),
  { ssr: false },
)
const DailyDog = dynamic(() => import('@/components/home/DailyDog'), {
  ssr: true,
})
const Loader = dynamic(() => import('@/components/newLoader/Loader'))
const Explore = dynamic(() => import('@/components/home/BreedListExplorer'))

export const config = {
  unstable_runtimeJS: true,
}

export default function Home({
  isDiscountBannerClosed,
  setIsDiscountBannerClosed,
  featuredPuppies,
  categoriesData,
  posts,
  breedersLocation,
  breedsExplorer,
  breedsListNames,
}) {
  const router = useRouter()
  const isMobile = useMediaQuery('(max-width:600px)')
  const isTablet = useMediaQuery('(max-width:1194px)')

  const [isLoading, setIsLoading] = useState(false)

  const [setRef, visible] = useOnScreen({
    rootMargin: '0px',
    threshold: 0.01,
  })

  const setNewRoute = (key, value) => {
    setIsLoading(true)

    const path = !value
      ? '/search'
      : `search?${key}=${value}${
          key === 'zipcode' ? '&sort=closest' : '&sort=by-view-score'
        }`
    router
      .push(path)
      .then(() => window.scrollTo(0, 0))
      .then(() => setIsLoading(false))
  }

  useStoreClickIds() /* grab google and facebook click id from url and save it to local storage */

  return (
    <div className={styles.container}>
      <BasicHeadSeo
        title="Mawoo Pets: Adorable Puppies from Caring Breeders"
        description="Find your puppy from 1,000+ trusted breeders nationwide. We help caring pet parents to find and bring a furbaby to their forever homes. Explore our puppies, take our breed match quiz, or get in touch with our customer service team."
        image={`${process.env.NEXT_PUBLIC_ROOT_URL}/mawoo_social_1600x906.png`}
        url={`${process.env.NEXT_PUBLIC_ROOT_URL}/`}
        content={
          process.env.NEXT_PUBLIC_ROOT_URL ===
          'https://qa-front.friendapuppy.com'
            ? 'noindex, nofollow'
            : 'index, follow'
        }
      />
      {isLoading && (
        <div className={styles.loader}>
          <Loader theme="tall" />
        </div>
      )}
      <Header
        isDiscountBannerClosed={isDiscountBannerClosed}
        setIsDiscountBannerClosed={setIsDiscountBannerClosed}
      />
      <Hero
        setNewRoute={setNewRoute}
        isMobile={isMobile}
        isTablet={isTablet}
        isDiscountBannerClosed={isDiscountBannerClosed}
      />
      <PicCardMix pickedPuppies={featuredPuppies} />
      <div className={styles.section}>
        <Explore title="Explore by Breed" breeds={breedsExplorer} />
      </div>

      <CustomerReview />
      <How />
      <Why />

      <div className={styles.section}>
        <OurPartners />
        <PuppyQuizBanner />
        <Categories
          title="Explore by Category"
          isLanding={false}
          categories={Object.keys(categoriesData?.categories || {}).filter(
            (c) => c !== '__typename',
          )}
        />
      </div>

      <div ref={setRef}>
        {visible && (
          <DailyDog
            posts={posts}
            categories={categoriesData}
            breedersLocation={breedersLocation}
            breedsExplorer={breedsListNames}
          />
        )}
      </div>

      <Footer />
    </div>
  )
}

export async function getStaticProps() {
  const apolloClient = await (
    await import('../utils/apolloClient')
  ).initializeApollo()
  const getAllPosts = (await import('../components/blog/lib/cosmic'))
    .getAllPosts
  await import('../utils/tokenStorage').then((mod) => mod.clearToken()) // Ignore cookies for this request

  let categoriesData
  let explorer
  let breedNameList
  let locations
  let newFeatured

  try {
    const { data: _newFeatured = '' } = await apolloClient.query({
      query: PUPPY_SEARCH,
      variables: { sort: 'by-view-score', limit: 50 },
    })
    newFeatured =
      _newFeatured !== '' ? categoryPairs(_newFeatured?.search?.rows) : []

    const { data: _explorer } = await apolloClient.query({
      query: BREED_LIST_EXPLORER,
      variables: { limit: 12 },
    })
    explorer = _explorer

    const { data: nameListExplorer = '' } = await apolloClient.query({
      query: GET_BREEDS_NAME_LIST,
    })
    breedNameList = nameListExplorer

    const { data: _categoriesData = {} } = await apolloClient.query({
      query: GET_CATEGORIES,
    })
    categoriesData = _categoriesData

    const { data: _locations = '' } = await apolloClient.query({
      query: GET_ALL_LOCATIONS,
    })
    locations = _locations
  } catch (error) {
    console.error('HOME QUERY ERROR', error)
  }

  const posts = await getAllPosts() // Cosmic posts

  return {
    props: {
      featuredPuppies: newFeatured,
      categoriesData,
      posts,
      breedsExplorer: explorer?.breedsList || {},
      breedersLocation: locations,
      breedsListNames: breedNameList?.breedsExplorer || null,
    },
    revalidate: 1 * 60 * 60, // Revalidate every hour
  }
}
